import {Box, em, Flex, Image, Loader, Text, Title} from "@mantine/core";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import {getAllFavouriteProductsAction} from "../../../store/product/productActions.ts";
import {useMediaQuery} from "@mantine/hooks";
import ProductCardNew from "./ProductCardNew.tsx";

const FavouriteProducts = () => {
    const dispatch = useAppDispatch()
    const isSmall = useMediaQuery(`(max-width: ${em(768)})`)
    const {favouriteProducts, loaders} = useAppSelector(state => state.product)
    useEffect(() => {
        dispatch(getAllFavouriteProductsAction())
    }, []);
    return (
        <>
            {loaders.gettingAllFavouriteProducts ?
                <Flex h="100vh" w="100%" align="center" justify="center">
                    <Loader/>
                </Flex> :
                favouriteProducts?.favorites?.length === 0 ?
                    <Flex w="100%" h="80vh" direction="column" justify="center" align="center" my="xl" py="xl">
                        <Box h={200} w={200}>
                            <Image h={190} w={190}
                                   src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724749741/Empty%20Screens/tqkbyzy8ls2dkdk5eovr.png"/>
                        </Box>
                        <Text>Add products to <span style={{color: "#62BE12"}}>Wishlist!</span></Text>
                    </Flex> :
                    <Flex justify="center" align="center" p="sm">
                        <Flex direction="column" w={isSmall ? "100%" : 1240} maw={isSmall ? "100%" : 1240}>
                            <Title order={3}>My Wishlist</Title>
                            {/*<Card shadow="sm" w="100%">*/}
                            {/*    {favouriteProducts?.favorites?.map((p) => {*/}
                            {/*        return (*/}
                            {/*            <>*/}
                            {/*                <Flex justify="space-between" align="center" my="sm">*/}
                            {/*                    <Flex gap="lg" align="center">*/}
                            {/*                        <Box h={100} w={100}>*/}
                            {/*                            <Image src={p?.productImage}/>*/}
                            {/*                        </Box>*/}
                            {/*                        <Flex direction="column">*/}
                            {/*                            <Text lineClamp={2}>{p?.productName}</Text>*/}
                            {/*                            <Text*/}
                            {/*                                opacity={0.5}>{p?.productPrice?.amount}{" "} {p?.productPrice?.currency}</Text>*/}
                            {/*                        </Flex>*/}
                            {/*                    </Flex>*/}
                            {/*                    <Flex>*/}
                            {/*                        <ActionIcon*/}
                            {/*                            onClick={() => dispatch(removeFromFavouriteAction({productId: p?._id}))}*/}
                            {/*                            radius="xl" size="lg" color="#F0F1F5" c="black">*/}
                            {/*                            <IconTrash/>*/}
                            {/*                        </ActionIcon>*/}
                            {/*                    </Flex>*/}
                            {/*                </Flex>*/}
                            {/*                <Divider size="xs" variant="dotted"/>*/}
                            {/*            </>*/}
                            {/*        )*/}
                            {/*    })}*/}
                            {/*</Card>*/}
                            <Flex wrap="wrap" justify={isSmall ? "center" : ""}>
                                {favouriteProducts?.favorites?.map((product, index) => {
                                    return (
                                        <ProductCardNew key={index} product={product} favourite={true}/>
                                    )
                                })}
                            </Flex>
                        </Flex>
                    </Flex>
            }

        </>
    );
};

export default FavouriteProducts;