import {useMediaQuery} from "@mantine/hooks";
import {BackgroundImage, Card, em, Flex, Image} from "@mantine/core";
import React from "react";
import logo from "../../assets/logo.png"
import {Navigate, useNavigate} from "react-router-dom";
import {getToken} from "../../utils/storage.ts";
import scooter from "../../assets/login-scooter.png"
import background from "../../assets/login-background.png"

type CenterLayoutProps = {
    children: React.ReactNode
}
const CenterLayout = ({children}: CenterLayoutProps) => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const isLaptop = useMediaQuery(`(max-width: ${em(992)})`);
    const navigate = useNavigate()

    if (getToken()) {
        return <Navigate to="/buyer" replace={true}/>
    }

    return (
			<BackgroundImage h='100vh' autoFocus src={background}>
				<Image
					loading='eager'
					src={logo}
					pos={'absolute'}
					top={10}
					left={30}
					w={190}
					onClick={() => navigate('/')}
					style={{ cursor: 'pointer' }}
				/>

				<Flex w='100%' justify='space-evenly' align='center' h='100%' p='sm'>
					<Image
						visibleFrom='sm'
						h='60%'
						w='50%'
						src={scooter}
						loading='eager'
					/>
					<Card
						w={isMobile ? '100%' : isLaptop ? '50%' : '30%'}
						mr={isMobile ? 0 : 80}
						ml={isMobile ? 0 : 180}
						bg={'#FFFFFFED'}
						radius={isMobile ? '20px' : '40px'}
					>
						{children}
					</Card>
				</Flex>
			</BackgroundImage>
		)
};

export default CenterLayout;