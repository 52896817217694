import {ActionIcon, Avatar, Card, em, Flex, LoadingOverlay, ScrollArea, Text, TextInput} from "@mantine/core";
import {IconSend2} from "@tabler/icons-react";
import {useMediaQuery} from "@mantine/hooks";
import {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../store/store.ts";
import {getOneChatAction, sendMessageAction} from "../../../../store/chat/chatActions.ts";
import {ISendMessage} from "../../../../interfaces/chat/ISendMessage.ts";
import {useLocation, useParams} from "react-router-dom";
import {formatMessageTime} from "../../../../utils/timeConverter.ts";
import {getUserId} from "../../../../utils/storage.ts";

const Chat = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const [message, setMessage] = useState("")
    const [messages, setMessages] = useState<string[]>([])
    const dispatch = useAppDispatch()
    const {id} = useParams()
    const {singleChatDetail, loaders} = useAppSelector(state => state.chat)
    const userId = getUserId()
    const viewport = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const name = searchParams.get('name');
    const avatar = searchParams.get('avatar');

    const scrollToBottom = () =>
        viewport.current!.scrollTo({top: viewport.current!.scrollHeight, behavior: 'instant'});


    const handleSend = () => {
        if (!id) return
        if (id === userId) return;
        if (message.length === 0) return;
        setMessages([...messages, message])
        const messageDate: ISendMessage = {
            recieverId: id,
            text: message,
            type: "text"
        }
        setMessage("")
        dispatch(sendMessageAction(messageDate))
    }

    useEffect(() => {
        scrollToBottom()
    }, [singleChatDetail?.chat?.messages]);


    useEffect(() => {
        if (!id) return
        dispatch(getOneChatAction({receiverId: id}))
    }, []);


    return (
        <Flex direction="column" justify="center" align="center" bg="#F1F1F1">
            <LoadingOverlay visible={loaders?.gettingSingleChat}/>
            <Card shadow="sm" pos="fixed" top={80} w={isMobile ? "100%" : "70%"} style={{zIndex: 1}}>
                <Flex align="center" gap="sm">
                    <Avatar src={avatar}/>
                    <Text>{name}</Text>
                </Flex>
            </Card>
            <Flex h={isMobile ? "74vh" : "76vh"} mt={70} mb="xl" bg="white" direction="column"
                  w={isMobile ? "100%" : "70%"}>
                <ScrollArea w="100%" viewportRef={viewport}>
                    <Flex direction="column" mb="lg">
                        {singleChatDetail?.chat?.messages?.map((m) => {
                            return (
                                <>
                                    <Card key={m._id} opacity={m?.sender === id ? 1 : 0.7}
                                          bg={m?.sender === id ? "#F3F4F8" : "green"}
                                          radius="lg"
                                          style={{alignSelf: m?.sender === id ? "flex-start" : "flex-end"}}
                                          p="sm" mx="sm" my="xs">
                                        <div dangerouslySetInnerHTML={{__html: m?.text}}/>
                                        {/*<Text>{m.text}</Text>*/}
                                    </Card>
                                    <Text opacity={0.5} size="sm" mx="sm"
                                          ta={m?.sender === id ? "start" : "end"}>{formatMessageTime(m.createdAt)}</Text>
                                </>
                            )
                        })}
                    </Flex>
                </ScrollArea>
            </Flex>
            <Card pos="fixed" bottom={0} w={isMobile ? "100%" : "70%"} pt="2px">
                <Flex align="center" gap="sm">
                    <form onSubmit={(event) => {
                        event.preventDefault()
                        handleSend()
                    }}
                          style={{display: "flex", alignItems: "center", width: "100%", gap: "5px"}}>
                        <TextInput value={message} onChange={(e) => setMessage(e.target.value)} w="100%" color="#F3F4F8"
                                   radius="xl"/>
                        <ActionIcon component="button" type="submit" size="xl" color="#F3F4F8" radius="xl"
                                    c="#767C8C">
                            <IconSend2/>
                        </ActionIcon>
                    </form>
                </Flex>
            </Card>
        </Flex>
    );
};

export default Chat;