import axios from "axios";
import {getCentre, getToken} from "../utils/storage.ts";

const axiosInstance = axios.create({
    baseURL: "https://tigeritserver-production-90fa.up.railway.app/api/v1/",
    // baseURL: "http://192.168.1.23:3000/api/v1/",
    headers: {
        Accept: "application/json",
    },
});

axiosInstance.interceptors.request.use((config) => {
    const token = getToken();
    const centre = getCentre();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    } // Add any other headers as needed
    config.headers["latitude"] = centre?.lat || "0"; // Replace 'your_latitude_value' with the actual latitude value
    config.headers["longitude"] = centre?.lng || "0"; // Replace 'your_longitude_value' with the actual longitude value
    return config;
});

export default axiosInstance;
