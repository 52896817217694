import {Card, em, Flex, Skeleton} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";

const ProductInfoSkeleton = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    return (
        <Flex my="sm" w={isMobile ? '165px' : '185px'}
              h={isMobile ? '260px' : '260px'} ml="xs"
              style={{cursor: "pointer"}}
        >
            <Card w="100%" pos="relative" withBorder radius="sm" p={0}>
                <Flex direction="column" justify="space-between" p="sm">
                    <Flex align="center" justify="center">
                        <Skeleton width={isMobile ? "160px" : "300px"} height="130px"/>
                    </Flex>
                    <Flex direction="column" py="sm">
                        <Skeleton height={8} width="100%"/>
                        <Flex gap="xs">
                            <Skeleton height={8} my="sm" width="100%"/>
                        </Flex>
                    </Flex>
                </Flex>
            </Card>
        </Flex>
    );
};

export default ProductInfoSkeleton;