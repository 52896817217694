import {useDisclosure, useMediaQuery} from '@mantine/hooks';
import {
    ActionIcon,
    Anchor,
    AppShell,
    Avatar,
    Box,
    Burger,
    Button,
    Card,
    Drawer,
    em,
    Flex,
    Group,
    Image,
    Indicator,
    Menu,
    NavLink,
    SegmentedControl,
    Text
} from '@mantine/core';
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {
    IconBell,
    IconCategory,
    IconChevronDown,
    IconClipboardList,
    IconHeart,
    IconLogout,
    IconMapPin,
    IconMessage,
    IconShoppingCart,
    IconTag,
    IconUser,
    IconWallet
} from "@tabler/icons-react";
import {clearToken, getToken, getUser, setAddress, setMapCentre} from "../../utils/storage.ts";
import {useEffect, useState} from "react";
import {modals} from "@mantine/modals";
import axiosInstance from '../../config/axios.config.ts';
import {useAppDispatch, useAppSelector} from "../../store/store.ts";
import GoogleMapModal from "../modals/GoogleMapModal.tsx";
import {getAllCartItems, getRecentSearchesAction} from "../../store/product/productActions.ts";
import logo from "../../assets/logo.png"
import {notificationSystem} from "../../utils/notificationSystem.ts";
import {getAllCategoriesAction} from "../../store/category/categoriesActions.ts";
import CustomMultiSelect from "../CustomMultiSelect.tsx";
import {setCentre, setNavbarAddress} from '../../store/account/accountSlice.ts';
import GoogleMapDrawer from "../drawers/GoogleMapDrawer.tsx";
import useSocketHook from "../../hooks/useSocketHook.tsx";
import mobileLogo from "../../assets/tiger_it_logo_66-01.png"
import navBar from "../../assets/navbar-logo.png"
import {UserButton} from "./UserButton.tsx";
import classes from "./BuyerNavbar.module.css"
import fallbackImage from "../../assets/Placholder-01.png";

interface MapProps {
    lat?: number,
    lng?: number
}

export function BuyerNavbar() {
    const [opened, {toggle}] = useDisclosure();
    const navigate = useNavigate()
    useSocketHook()
    const isMobile = useMediaQuery(`(max-width: ${em(576)})`);
    const isLaptop = useMediaQuery(`(max-width: ${em(1024)})`);
    const dispatch = useAppDispatch()
    const [mapOpened, {open, close}] = useDisclosure(false);
    const [error, setError] = useState<string | null>(null);
    const [coordinates, setCoordinates] = useState<MapProps>({lat: 0, lng: 0})
    const {cartItems, allRecentSearches} = useAppSelector(state => state.product)
    const {address} = useAppSelector(state => state.account)
    const [bgColor, setBgColor] = useState("")
    const location = useLocation();
    const [multipleSelectType, setMultipleSelectType] = useState("product")
    const [activePath, setActivePath] = useState("")

    const user = getUser()

    useEffect(() => {
        const path = location.pathname;

        const isChat = path.includes('chat');
        setBgColor(isChat ? "#F1F1F1" : "white");

        const isRestaurant = path.includes('sellers-food-shop') || path.includes('search-food');
        const newSelectType = isRestaurant ? "food" : "product";

        // Only dispatch the action and update the select type if it actually changes
        setMultipleSelectType((currentSelectType) => {
            if (currentSelectType !== newSelectType) {
                dispatch(getRecentSearchesAction(newSelectType));
                return newSelectType;
            }
            return currentSelectType;
        });

    }, [location.pathname]);

    useEffect(() => {
        // dispatch(getProfileDataAction())
        dispatch(getAllCartItems())
        dispatch(getAllCategoriesAction())
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/firebase-messaging-sw.js')
                .then((registration) => {
                    console.log('Service Worker registered with scope:', registration.scope);
                })
                .catch((err) => {
                    console.error('Service Worker registration failed:', err);
                });
        } else {
            console.error('Service workers are not supported in this browser.');
        }

        const lastPathName = location.pathname.substring(1).split('/').pop()
        if (lastPathName) {
            setActivePath(lastPathName);
        }
        console.log("profile pic", user?.profilePic)
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude
                    const long = position.coords.longitude
                    setError(null);
                    setCoordinates({...coordinates, lat: lat, lng: long})
                    dispatch(setCentre({lat: lat, lng: long}))
                    setMapCentre({lat: lat, lng: long})
                    axiosInstance.get(`geocode/address-from-coords?latitude=${lat}&longitude=${long}`).then((value) => {
                        dispatch(setNavbarAddress(value.data.response.formattedAddress))
                        setAddress(value.data.response)
                    })
                },
                (error) => {
                    setError(error.message);
                }
            );
        } else {
            setError("Geolocation is not supported by this browser.");
        }
        console.log(error)
    }, []);


    if (!getToken()) {
        return <Navigate to="/" replace={true}/>
    }

    return (
        <AppShell
            header={{height: 80}}
            navbar={{width: 300, breakpoint: 'lg', collapsed: {desktop: !opened, mobile: !opened}}}
            footer={{height: 80, collapsed: true}}
        >
            <AppShell.Header bg="#62BE12">
                <Group h="100%" w="100%" px="md">
                    <Flex gap={isMobile ? "" : "xl"} w="100%" h={isMobile ? "100%" : ""}
                          direction={isMobile ? "column" : "row"}
                          justify={isMobile ? "space-around" : "space-around"}
                          align={isMobile ? "start" : "center"}
                    >
                        <Flex w="100%" align="center" justify={isMobile ? "space-between" : ""} gap="lg"
                              hiddenFrom="md">
                            <Flex hiddenFrom="md" gap="sm" align="center">
                                <Burger hiddenFrom="sm" opened={opened} onClick={toggle} size="sm" color="white"/>
                                <Box h={30} w={85} onClick={() => navigate("/buyer")}>
                                    <Image radius="xs" src={mobileLogo} fallbackSrc={fallbackImage}/>
                                </Box>
                            </Flex>
                            <Flex visibleFrom="md">
                                {multipleSelectType === "food" ?
                                    <CustomMultiSelect selectData={allRecentSearches?.searches} selectType="food"/> :
                                    <CustomMultiSelect selectData={allRecentSearches?.searches} selectType="product"/>
                                }
                            </Flex>
                            <Group gap="xs" h="100%">
                                <ActionIcon onClick={open} radius="xl" size="xl" variant="white" c="black">
                                    <IconMapPin style={{color: address ? "green" : ""}}/>
                                </ActionIcon>
                                {cartItems > 0 ? <Indicator color="red" inline label={cartItems} size={16}
                                                            offset={4}>
                                        <Card style={{cursor: "pointer"}} p="xs" radius="xl"
                                              onClick={() => navigate("my-bag")}>
                                            <Image h={23} w={23} fallbackSrc={fallbackImage}
                                                   src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724744725/Icons/izqyvmnxdaxekaf7hiyt.png"/>
                                        </Card>
                                    </Indicator> :
                                    <Card style={{cursor: "pointer"}} p="xs" radius="xl"
                                          onClick={() => navigate("my-bag")}>
                                        <Image h={23} w={23} fallbackSrc={fallbackImage}
                                               src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724744725/Icons/izqyvmnxdaxekaf7hiyt.png"/>
                                    </Card>
                                }
                            </Group>
                        </Flex>
                        <Image fallbackSrc={fallbackImage} style={{cursor: "pointer"}}
                               onClick={() => navigate("/buyer")} src={logo} h="40px"
                               w="300px" visibleFrom="md"/>
                        <Flex align="center" w={isMobile ? "60%" : "80%"} visibleFrom="md">
                            <Flex gap="xl" w="100%" justify="space-around">
                                {multipleSelectType === "food" ?
                                    <CustomMultiSelect selectData={allRecentSearches?.searches} selectType="food"/> :
                                    <CustomMultiSelect selectData={allRecentSearches?.searches} selectType="product"/>
                                }
                                <Card p={isLaptop ? "xs" : ""} style={{cursor: "pointer"}} onClick={open}
                                      w={isLaptop ? "350px" : "250px"}
                                      h="50px" radius="xl"
                                      bg="#58AB10">
                                    <Flex w="100%" h="100%" gap="xs" align="center" justify="space-between">
                                        <ActionIcon radius="xl" size={isLaptop ? "md" : "lg"} color="#3B720B" c="white">
                                            <IconMapPin/>
                                        </ActionIcon>
                                        <Flex w={isLaptop ? 80 : 150} direction="column">
                                            <Text size={isLaptop ? "xs" : "sm"} c="white">Deliver to</Text>
                                            <Anchor size={isLaptop ? "xs" : "sm"} c="white" truncate="end">
                                                {address}
                                            </Anchor>
                                        </Flex>
                                        <IconChevronDown color="white"/>
                                    </Flex>
                                </Card>
                                <Menu withArrow>
                                    <Menu.Target>
                                        <Flex align="center" gap="sm" style={{cursor: "pointer"}}>
                                            <Avatar src={user?.profilePic}/>
                                            <Flex direction="column">
                                                <Text c="white" size="xs">Hi There!</Text>
                                                <Box w={120}>
                                                    <Text truncate="end" c="white">{user?.firstName}</Text>
                                                </Box>
                                            </Flex>
                                            <IconChevronDown color="white"/>
                                        </Flex>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        <Menu.Item onClick={() => navigate("/seller")}>
                                            <Button fw={200} w="100%" color="#62BE12"
                                                    variant="outline"
                                                    radius="xl">Switch to Seller</Button>
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => navigate("my-account")}
                                            leftSection={<Avatar size="xs" radius="xs"
                                                                 src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724745092/Icons/qrbbzmuo6amg75ujftdm.png"/>}>
                                            My Account
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => navigate("all-categories")}
                                            leftSection={<Avatar size="xs" radius="xs"
                                                                 src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1725520235/Icons/ogarmdxgqhb1885dtyli.png"/>}
                                        >
                                            Categories
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => navigate("my-bag")}
                                            leftSection={<Avatar size="xs" radius="xs"
                                                                 src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724665686/Website/Buyer%20Web%20Icons/p6ncrii0lk3zksthin7b.png"/>}>
                                            My Cart
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => navigate("favourite-products")}
                                            leftSection={<Avatar size="xs" radius="xs"
                                                                 src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1725520235/Icons/xnrwhsflxerncnbhsdha.png"/>}>
                                            Wishlist
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => navigate("coupons")}
                                            leftSection={<Avatar size="xs" radius="xs"
                                                                 src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724665686/Website/Buyer%20Web%20Icons/vgjbpct8kiteg7xaoozy.png"/>}>
                                            Coupons
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => navigate("inbox")}
                                            leftSection={<Avatar size="xs" radius="xs"
                                                                 src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724665687/Website/Buyer%20Web%20Icons/g7vwobp0k7swqx1wdvmi.png"/>}>
                                            Inbox
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => navigate("my-wallet")}
                                            leftSection={<Avatar size="xs" radius="xs"
                                                                 src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724665686/Website/Buyer%20Web%20Icons/hybhpuwyakdwpdwuyc2l.png"/>}>
                                            My Wallet
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => navigate("my-orders")}
                                            leftSection={<Avatar size="xs" radius="xs"
                                                                 src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724665686/Website/Buyer%20Web%20Icons/m7tjdjtsnderfgus75mw.png"/>}>
                                            My Orders
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => navigate("notification")}
                                            leftSection={<IconBell size="1rem" stroke={1.5}/>}>
                                            Notification
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => window.open("https://tawk.to/chat/65eea7698d261e1b5f6b657f/1hom3rtkb", "_blank")}
                                            leftSection={<Avatar size="xs" radius="xs"
                                                                 src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724665686/Website/Buyer%20Web%20Icons/otlry0al5wbxa6cftu0v.png"/>}>
                                            Customer Support
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => {
                                                modals.openConfirmModal({
                                                    title: 'Logout your profile',
                                                    centered: true,
                                                    children: (
                                                        <Text size="sm">
                                                            Are you sure you want to Logout your account?
                                                        </Text>
                                                    ),
                                                    labels: {confirm: 'Logout', cancel: "cancel"},
                                                    confirmProps: {color: 'red'},
                                                    onCancel: () => console.log('Cancel'),
                                                    onConfirm: () => {
                                                        clearToken()
                                                        navigate("/")
                                                        toggle()
                                                        notificationSystem("Logged out", "Logged out successfully now you are in guest mode", false)
                                                    },
                                                });
                                            }}
                                            color="red"
                                            leftSection={<IconLogout size="1rem" stroke={1.5}/>}>
                                            Logout
                                        </Menu.Item>
                                    </Menu.Dropdown>
                                </Menu>

                            </Flex>
                        </Flex>
                        {cartItems > 0 ?
                            <Indicator visibleFrom="md" label={cartItems} size={16} offset={6} color="red">
                                <Card visibleFrom="md" style={{cursor: "pointer"}} p="xs" radius="xl"
                                      onClick={() => navigate("my-bag")}>
                                    <Image h={25} w={25}
                                           src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724744725/Icons/izqyvmnxdaxekaf7hiyt.png"/>
                                </Card>
                            </Indicator> :
                            <Card visibleFrom="md" style={{cursor: "pointer"}} p="xs" radius="xl"
                                  onClick={() => navigate("my-bag")}>
                                <Image h={25} w={25}
                                       src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724744725/Icons/izqyvmnxdaxekaf7hiyt.png"/>
                            </Card>
                        }
                    </Flex>
                </Group>
            </AppShell.Header>
            <Drawer.Root classNames={{body: classes.body}} size={isMobile ? "xs" : "sm"} opened={opened}
                         onClose={toggle}
            >
                <Drawer.Overlay/>
                <Drawer.Content>
                    <Drawer.Header>
                        <Drawer.Title>
                            <Image src={navBar} w="130px"/>
                        </Drawer.Title>
                        <Drawer.CloseButton/>
                    </Drawer.Header>
                    <Drawer.Body h="92%" p={0}>
                        <Flex justify="space-between" h="100%" direction="column">
                            <Flex direction="column" px="sm">
                                <Flex w="100%" justify="center">
                                    <SegmentedControl fw={200} w="60%" color="#62BE12"
                                                      onClick={() => navigate("/seller")}
                                                      radius="xl"
                                                      data={[
                                                          {
                                                              value: 'seller',
                                                              label: 'Seller',
                                                              disabled: true,
                                                          },
                                                          {
                                                              value: 'buyer',
                                                              label: 'Buyer',
                                                          }
                                                      ]}/>
                                    {/*<Button onClick={() => navigate("/seller")} fw={200} color="#62BE12"*/}
                                    {/*        variant="outline"*/}
                                    {/*        radius="xl">Switch to Seller</Button>*/}
                                </Flex>
                                <NavLink mt="sm" style={{borderRadius: "8px"}} color="lime"
                                         variant="filled" active={activePath === "my-account"}
                                         leftSection={<IconUser size="1rem" stroke={1.5}/>}
                                         label="My Account" onClick={() => {
                                    navigate("my-account")
                                    setActivePath("my-account")
                                    toggle()
                                }}/>
                                <NavLink style={{borderRadius: "8px"}} color="lime"
                                         variant="filled" active={activePath === "all-categories"}
                                         leftSection={<IconCategory size="1rem" stroke={1.5}/>}
                                         label="Categories" onClick={() => {
                                    navigate("all-categories")
                                    setActivePath("all-categories")
                                    toggle()
                                }}/>
                                <NavLink style={{borderRadius: "8px"}} color="lime"
                                         variant="filled"
                                         active={activePath === "my-bag"}
                                         leftSection={<IconShoppingCart size="1rem" stroke={1.5}/>}
                                         label="My Cart" onClick={() => {
                                    navigate("my-bag")
                                    setActivePath("my-bag")
                                    toggle()
                                }}/>
                                <NavLink style={{borderRadius: "8px"}} color="lime"
                                         variant="filled"
                                         active={activePath === "inbox"}
                                         leftSection={<IconMessage size="1rem" stroke={1.5}/>}
                                         label="Inbox" onClick={() => {
                                    navigate("inbox")
                                    setActivePath("inbox")
                                    toggle()
                                }}/>
                                <NavLink style={{borderRadius: "8px"}} color="lime"
                                         variant="filled"
                                         active={activePath === "favourite-products"}
                                         leftSection={<IconHeart size="1rem" stroke={1.5}/>}
                                         label="Wishlist" onClick={() => {
                                    navigate("favourite-products")
                                    setActivePath("favourite-products")
                                    toggle()
                                }}/>
                                <NavLink style={{borderRadius: "8px"}} color="lime"
                                         variant="filled"
                                         active={activePath === "coupons"}
                                         leftSection={<IconTag size="1rem" stroke={1.5}/>}
                                         label="Coupons" onClick={() => {
                                    navigate("coupons")
                                    setActivePath("coupons")
                                    toggle()
                                }}/>

                                <NavLink style={{borderRadius: "8px"}} color="lime"
                                         variant="filled" active={activePath === "my-wallet"}
                                         leftSection={<IconWallet size="1rem" stroke={1.5}/>}
                                         label="My Wallet" onClick={() => {
                                    navigate("my-wallet")
                                    setActivePath("my-wallet")
                                    toggle()
                                }}/>
                                <NavLink style={{borderRadius: "8px"}} color="lime"
                                         variant="filled" active={activePath === "my-orders"}
                                         leftSection={<IconClipboardList size="1rem" stroke={1.5}/>}
                                         label="My Orders" onClick={() => {
                                    navigate("my-orders")
                                    setActivePath("my-orders")
                                    toggle()
                                }}/>

                                <NavLink style={{borderRadius: "8px"}} color="lime"
                                         variant="filled" active={activePath === "notification"}
                                         leftSection={<IconBell size="1rem" stroke={1.5}/>}
                                         label="Notification" onClick={() => {
                                    navigate("notification")
                                    setActivePath("notification")
                                    toggle()
                                }}/>
                                <NavLink style={{alignSelf: "end"}} leftSection={<IconLogout size="1rem" stroke={1.5}/>}
                                         c="red" label="logout"
                                         onClick={() => {
                                             modals.openConfirmModal({
                                                 title: 'Logout your profile',
                                                 centered: true,
                                                 children: (
                                                     <Text size="sm">
                                                         Are you sure you want to Logout your account?
                                                     </Text>
                                                 ),
                                                 labels: {confirm: 'Logout', cancel: "cancel"},
                                                 confirmProps: {color: 'red'},
                                                 onCancel: () => console.log('Cancel'),
                                                 onConfirm: () => {
                                                     clearToken()
                                                     navigate("/")
                                                     toggle()
                                                     notificationSystem("Logged out", "Logged out successfully now you are in guest mode", false)
                                                 },
                                             });
                                         }}/>
                            </Flex>
                            <Flex>
                                <UserButton toggle={toggle} isSeller={false}/>
                            </Flex>
                        </Flex>
                    </Drawer.Body>
                </Drawer.Content>
            </Drawer.Root>
            <AppShell.Main bg={bgColor || "white"}>
                <Outlet/>
            </AppShell.Main>
            <AppShell.Footer style={{backgroundColor: "black"}} p="md">Footer</AppShell.Footer>
            {isMobile ? <GoogleMapDrawer opened={mapOpened} onClose={close}/> :
                <GoogleMapModal opened={mapOpened} onClose={close}/>}
        </AppShell>
    );
}